const posts = [
  {
    title: 'Migrating Your React Native iOS Project to Use CocoaPods',
    link: 'https://medium.com/@jrapala/migrating-your-react-native-ios-project-to-use-cocoapods-6feaca235d7e',
  },
  {
    title: 'How to Upgrade from React Native 0.57 to 0.59 | Part 2: Upgrading to 0.59',
    link: 'https://medium.com/@jrapala/how-to-upgrade-from-react-native-0-57-to-0-59-part-2-upgrading-to-0-59-32323f49857d',
  },
  {
    title: 'How to Upgrade from React Native 0.57 to 0.59 | Part 1: Upgrading to 0.58',
    link: 'https://medium.com/@jrapala/how-to-upgrade-from-react-native-0-57-to-0-59-part-1-upgrading-to-0-58-1d6a672186cb',
  },
  {
    title: 'How to Replace a React Native ListView Component with a FlatList Component',
    link: 'https://medium.com/@jrapala/how-to-replace-a-react-native-listview-component-with-a-flatlist-component-2fe8eaf2e296',
  },
  {
    title:
      'Tutorial: How to Fix a Failing Android Build Due to [androidx.core:core:1.0.0] Issues (June 2019)',
    link: 'https://medium.com/@jrapala/tutorial-how-to-fix-a-failing-android-build-due-to-androidx-core-core-1-0-0-issues-june-2019-cbfb5d94de27',
  },
];

export default posts;
